<!-- 
  
-->
<template>
  <img ref="imgRef" :src="finalSrc" v-bind="$attrs" v-on="$listeners">
</template>

<script>
import { indexedDBImages } from '@/utils/indexedDB'
import axios from 'axios'
import { cacheImageRequestMap } from './sameRequest'

export default {
  name: 'CacheImage',
  props: {
    /** 图片地址 */
    src: {
      type: String | undefined,
      required: true
    },
    /** 是否懒加载 */
    lazy: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      finalSrc: '',
      imgRef: null,
      isIntersecting: false,    // 是否在可视区
    }
  },
  watch: {
    src: {
      handler: async function (newVal) {

        if (!newVal) return

        this.renderImg()

      },
      immediate: true
    }
  },
  mounted() {

    // 保存DOM
    this.imgRef = this.$refs['imgRef']

    // 懒加载 开启观察者
    if (this.lazy) {
      // 观察者
      const observer = new IntersectionObserver((obs) => {
        // 观察的对象是否在可视区
        let isInViewport = obs[0].isIntersecting
        if (isInViewport) {
          // 更改状态
          console.log('进入可视区')
          this.isIntersecting = true
          this.renderImg()
          // 停止观察
          observer.unobserve(this.imgRef)
          // 关闭观察器实例
          observer.disconnect()
        }

      })
      observer.observe(this.imgRef)
    }

  },
  methods: {
    /** 渲染图片 */
    async renderImg() {

      // 不是懒加载 -> 直接渲染
      // 是懒加载 -> 图片进入可视区并且src不为空时，渲染
      if (this.lazy) {
        if (!this.isIntersecting || !this.src) return
      }

      // 查询是否存在缓存的图片
      let existItem = await indexedDBImages.getItem(this.src).then(result => {
        return result
      }).catch(function (err) {
        console.log('err', err)
        return null
      })

      // 查询是否存在缓存的图片
      if (!existItem) {
        // 不存在，则请求图片并缓存到本地，并保存响应头信息
        this.getResourceAndSave(this.src).then(result => {
          this.generateLocalURLAndSetToSrc(result.data)
        })
      } else {
        console.log(this.src, '从 IndexedDB 获取', existItem)

        // 存在则先渲染保存的二进制数据
        this.generateLocalURLAndSetToSrc(existItem.data)

        // 然后发起请求判断资源是否过期
        // 过期则重新请求，重新渲染
        let isExpired = await this.queryResourceIsExpired(this.src, existItem.headers)
        if (isExpired) {
          this.getResourceAndSave(this.src).then(result => {
            this.generateLocalURLAndSetToSrc(result.data)
          })
        }

      }

    },
    /** 生成本地链接并设置到scr */
    generateLocalURLAndSetToSrc(blob) {
      try {
        this.imgRef.src = window.URL.createObjectURL(blob)
      } catch (err) {
        console.log(err)
        this.imgRef.src = this.src
      }
    },
    /** 获取新资源并保存到数据库 */
    getResourceAndSave(url) {
      return new Promise(async (resolve, reject) => {

        // 先判断是否有相同的资源在加载
        // 有 -> 取出 Promise ，并等待完成
        // 无 -> 发送请求，并保存 Promise
        let existSameRequestPromise = cacheImageRequestMap.get(url)
        if (existSameRequestPromise) {
          console.log('等待相同资源加载完成', url)
          await existSameRequestPromise.then(res => {
            console.log('等待相同资源加载完成结果', res)
            resolve({
              ...res,
              data: res.data
            })
          })
        } else {
          let p = axios.get(url, { responseType: 'blob' })
          cacheImageRequestMap.set(url, p)

          await p.then(res => {

            indexedDBImages.setItem(url, {
              data: res.data,
              headers: res.headers
            }).then(result => {
              console.log('从服务器获取', res.headers)
              resolve(result)
            }).catch((err) => {
              console.log('IndexedDB错误', err)
              reject(err)
            })

          }).catch(err => {
            console.log('axios错误', err)
            reject(err)
          })
        }

      })
    },
    /** 判断资源是否过期 */
    queryResourceIsExpired(url, lastHeaders) {
      return new Promise((resolve, reject) => {
        // axios.options 返回405...
        axios.get(url, {
          headers: { range: 'bytes=0-0' }
        }).then(res => {
          if (lastHeaders['last-modified'] === res.headers['last-modified']) {
            resolve(false)
          } else {
            console.log('资源过期', lastHeaders['last-modified'], res.headers['last-modified'])
            resolve(true)
          }
        }).catch(err => {
          console.log('axios错误', err)
          reject(err)
        })
      })
    },
  }
}
</script>