<!--
 * @Description: 分页组件，因为element的分页组件需要绑定很多方法，传递很多参数，所以封装一下
-->
<template>
  <el-pagination class="part-pagination" :current-page.sync="currentPage" :page-size.sync="currentSize"
    :page-sizes="pageSizes" :layout="layout" :total="total" @current-change="handlePageChange"
    @size-change="handleSizeChange" background />
</template>

<script>
export default {
  name: 'PartPagination',
  props: {
    page: {
      type: Number,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [20, 40, 80]
      },
      // default() {
      //   return [20, 40, 80]
      // }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    currentSize: {
      get() {
        return this.size
      },
      set(val) {
        this.$emit('update:size', val)
      }
    }
  },
  methods: {
    handlePageChange() {
      this.$emit('change')
    },
    handleSizeChange() {
      this.$emit('update:page', 1)
      this.$emit('change')
    }
  }
}
</script>

<style lang='scss' scoped>
.part-pagination {
  text-align: center;
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>