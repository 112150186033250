import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// 自动导入 modules 下的所有模块
const importFunction = require.context('./modules', true, /\.js$/)
const modules = importFunction.keys().reduce((modules, modulePath) => {
  const currentModuleName = modulePath.replace(/\.\/(.*)\.js$/, '$1')
  const currentModule = importFunction(modulePath).default
  modules[currentModuleName] = currentModule
  return modules
}, {})


const store = new Vuex.Store({
  getters,
  modules
})

export default store
