
import { INIT_ROUTES, ADD_ROUTES } from '../mutation-types'
import baseRoutes from '@/router/routes'

const state = {
  routes: []
}

const mutations = {
  [INIT_ROUTES]: (state) => {
    state.routes = baseRoutes
  },
  [ADD_ROUTES]: (state, routes) => {
    if (routes instanceof Array) {
      state.routes = state.routes.concat(routes)
    } else if (routes instanceof Object) {
      state.routes = state.routes.concat([routes])
    }
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
