/*
 * @Description: 批量注册 @components 下的所有组件为全局组件
 * 
 * 1.批量导入需要使用一个函数 require.context(dir,deep,matching)  参数：1.目录  2.是否加载子目录  3.加载的正则匹配
 * 2.importFunction.keys() 返回文件相对路径的数组
 * 3.用这种方式注册组件，组件内必须有 name 属性
 */

import Vue from 'vue'

const importFunction = require.context('./', true, /\.js$/)

importFunction.keys().forEach(directivePath => {
  const directive = importFunction(directivePath).default
  if (directive?.name) {
    Vue.directive(directive.name, directive)
  }
})