/*
 * @Description: 
 */
import LayoutVisual from '@/layout/visual'

export default {
  path: '/visual-data',
  component: LayoutVisual,
  meta: { title: '可视化页面', hidden: true },
  children: [
    {
      path: 'module-diary',
      component: () => import('@/views/visual-data/module-diary/index'),
      meta: { title: '日记可视化', hidden: true },
    },
    {
      path: 'hot-statistics',
      component: () => import('@/views/visual-data/hot-statistics/index'),
      meta: { title: '热力图', hidden: true },
    },

    // ======================= 小说模块 =======================
    {
      path: 'module-novel-list',
      component: () => import('@/views/visual-data/module-novel/list'),
      meta: { title: '小说列表', hidden: true },
    },
    {
      path: 'module-novel-detail',
      component: () => import('@/views/visual-data/module-novel/detail'),
      meta: { title: '小说详情', hidden: true },
    },
    {
      path: 'module-novel-read',
      component: () => import('@/views/visual-data/module-novel/read'),
      meta: { title: '小说阅读', hidden: true },
    },
  ]
}