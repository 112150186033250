/** 
 * 当多个组件同时请求一张图片时，会导致一张图片被反复下载多次
 * 
 * 解决思路
 * 
 * 维护一个Map，key是资源地址，value是是否在加载
 * 当加载资源时，如果有同名资源在加载，则等待
 * 否则，则加载新资源
 * 加载资源完成后，不需要删除对应的key，因为代码逻辑走到这里的时候，加载的都是新资源，如果相同的key再次发起请求，就直接从本地加载了
 * 
 * 备注
 * 当我写完这个逻辑和修改前对比时发现
 * 修改前的多个相同的图片会发起多次请求，但是只有第一个请求是获取到数据的，后面的请求的响应数据的length都是0，不知道是浏览器优化的还是服务器优化的，有时间研究研究
 * 不过，不能说新添加的逻辑是多余的，因为首先锻炼了自己的能力，其次控制台里会少很多相同的请求，第三就是如果以后遇到类似的场景，会多一个思路
 */

/** 资源池 */
export const cacheImageRequestMap = new Map([])
