<!--
 * @Description: 图标组件
 * elementUI传递类名
 * iconfont传递id
-->
<template>
  <span class="part-icon" :style="{
    '--color': color,
    '--size': realSize,
  }" @click="handleCopy">
    <i :class="className"></i>
  </span>
</template>

<script>
import { iconfontList } from './icon'
export default {
  name: 'PartIcon',
  props: {
    tag: {
      type: String,
      default: '1101'     // 默认是网站的收藏家图标，elementUI传递类名，iconfont传递id
    },
    color: {
      type: String,
      default: '#3498db'
    },
    size: {               // 图标大小，16 16px都可以
      type: String,
      default: '16'
    },
    copy: {
      type: Boolean,       // 是否开启点击复制
      default: false
    }
  },
  data() {
    return {
      iconfontList
    }
  },
  computed: {
    className() {
      if (this.tag.startsWith('el')) {
        return this.tag
      } else {
        let item = this.iconfontList.find(item => item.icon_id === this.tag)
        return `iconfont ${item.font_class}`
      }
    },
    realSize() {
      // 为了方便接收 16 16px 这两种格式
      if (/px$/.test(this.size)) {
        return this.size
      } else {
        return this.size + 'px'
      }
    }
  },
  methods: {
    handleCopy() {
      if (!this.copy) {
        return
      }

      let partCode = `<part-icon tag="${this.tag}" />`

      let middleElement = document.createElement('input')
      middleElement.setAttribute('value', partCode)
      document.body.appendChild(middleElement)

      middleElement.select()
      document.execCommand('copy')
      document.body.removeChild(middleElement)

      this.$message.success('复制成功 ' + partCode)

    }
  }
}
</script>

<style lang='scss' scoped>
.part-icon {
  color: var(--color);
  font-size: var(--size);

  i {
    font-size: inherit;
  }
}
</style>