/*
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false      // 是否开启生产环境下的vue的警告提示

// 添加 vue-router
import router from './router'

// 配置导航守卫
import '@/router/guard'

// 添加 vuex
import store from './store'

// 配置 element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI, { size: 'medium' })

// 全局样式 部分覆盖element默认样式的代码，需要在引入element之后引入
import './style/index.scss'

// 注册 @components 下的组件为全局组件
import '@/components/install'

// 注册指令
import '@/directives/install'

// 添加http请求，挂载到vue原型上
import http from './network/http'
Vue.prototype.$http = http

new Vue({
  store,
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$eventBus = this
  }
}).$mount('#app')
