let callbacks = []

function loadedTinymce() {
  // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2144
  // check is successfully downloaded script
  return window.tinymce
}

const dynamicLoadScript = (src, callback) => {

  // 第一次加载时会将资源地址设置为script标签的id
  // 这里判断之前是否加载过
  const existingScript = document.getElementById(src)

  // 加载完成的回调方法
  const cb = callback || function () { }

  // 第一次加载资源
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = src
    script.id = src
    document.body.appendChild(script)

    callbacks.push(cb)

    // 根据script标签中有无 onload 方法区分IE和其它浏览器
    const onLoaded = 'onload' in script ? browerOnLoaded : ieOnLoaded

    onLoaded(script)

  }

  // 之前加载过资源
  if (existingScript && cb) {
    if (loadedTinymce()) {
      cb(null, existingScript)
    } else {
      callbacks.push(cb)
    }
  }

  function browerOnLoaded(script) {
    script.onload = function () {
      // this.onload = null here is necessary
      // because even IE9 works not like others
      this.onerror = this.onload = null
      for (const cb of callbacks) {
        cb(null, script)
      }
      callbacks = null
    }
    script.onerror = function () {
      this.onerror = this.onload = null
      cb(new Error('Failed to load ' + src), script)
    }
  }

  function ieOnLoaded(script) {
    script.onreadystatechange = function () {
      if (this.readyState === 'complete' && this.readyState === 'loaded') {
        this.onreadystatechange = null
        for (const cb of callbacks) {
          cb(null, script) // there is no way to catch loading errors in IE8
        }
        callbacks = null
      }
    }
  }
}

export default dynamicLoadScript
