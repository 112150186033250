/*
 * @Description: 
 */
import LayoutSystem from '@/layout/system'

export default [
  // {
  //   path: '/daily-record',
  //   component: LayoutSystem,
  //   redirect: '/daily-record/index',
  //   meta: { title: '日常模块', hidden: false, icon: '30132195' },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/system/daily-record/index'),
  //       meta: { title: '每日记录', hidden: false }
  //     },
  //     {
  //       path: 'visual',
  //       component: () => import('@/views/system/daily-record/visual'),
  //       meta: { title: '作息·可视化', hidden: false }
  //     },
  //     {
  //       path: 'visual-exercise',
  //       component: () => import('@/views/system/daily-record/visual-exercise'),
  //       meta: { title: '锻炼·可视化', hidden: false }
  //     }
  //   ]
  // },
  // {
  //   path: '/income-expense',
  //   component: LayoutSystem,
  //   meta: { title: '收支模块', hidden: false, icon: '30132182' },
  //   children: [
  //     {
  //       path: 'type',
  //       component: () => import('@/views/system/income-expense/type'),
  //       meta: { title: '收支类型', hidden: false }
  //     },
  //     {
  //       path: 'record',
  //       component: () => import('@/views/system/income-expense/record'),
  //       meta: { title: '收支记录', hidden: false }
  //     },
  //     {
  //       path: 'visual',
  //       component: () => import('@/views/system/income-expense/visual'),
  //       meta: { title: '可视化', hidden: false }
  //     },
  //   ]
  // },
  // {
  //   path: '/module-diary',
  //   component: LayoutSystem,
  //   redirect: '/module-diary/index',
  //   meta: { title: '日记模块', hidden: false, icon: '11753210' },
  //   children: [
  //     {
  //       path: 'module-diary',
  //       component: () => import('@/views/system/module-diary/index'),
  //       meta: { title: '日记记录', hidden: false }
  //     },
  //     {
  //       path: 'visual-github',
  //       component: () => import('@/views/system/module-diary/diary-visual'),
  //       meta: { title: '可视化', hidden: false, icon: '30132163' }
  //     },
  //   ]
  // },
  // {
  //   path: '/module-book',
  //   component: LayoutSystem,
  //   meta: { title: '图书模块', hidden: false, icon: '1004886' },
  //   children: [
  //     // {
  //     //   path: 'book-store',
  //     //   component: () => import('@/views/system/module-book/book-store'),
  //     //   meta: { title: '图书仓库', hidden: false }
  //     // },
  //     // {
  //     //   path: 'book-read',
  //     //   component: () => import('@/views/system/module-book/book-read'),
  //     //   meta: { title: '阅读记录', hidden: false }
  //     // },
  //     // {
  //     //   path: 'book-note',
  //     //   component: () => import('@/views/system/module-book/book-note'),
  //     //   meta: { title: '阅读笔记', hidden: false }
  //     // },
  //   ]
  // },
  {
    path: '/module-novel',
    component: LayoutSystem,
    meta: { title: '小说模块', hidden: false, icon: '1004886' },
    children: [
      {
        path: 'novel-list',
        component: () => import('@/views/system/module-novel/novel-list'),
        meta: { title: '小说仓库', hidden: false }
      },
    ]
  },
  {
    path: '/module-article',
    component: LayoutSystem,
    meta: { title: '文章模块', hidden: false, icon: '970762' },
    children: [
      {
        path: 'article-list',
        component: () => import('@/views/system/module-article/article-list'),
        meta: { title: '文章列表', hidden: false }
      },
      {
        path: 'article-visual',
        component: () => import('@/views/system/module-article/article-visual'),
        meta: { title: '可视化', hidden: false }
      }
    ]
  },
  // {
  //   path: '/module-music',
  //   component: LayoutSystem,
  //   meta: { title: '音乐模块', hidden: false, icon: '1029' },
  //   children: [
  //     {
  //       path: 'module-music',
  //       component: () => import('@/views/system/module-music/index'),
  //       meta: { title: '资源管理', hidden: false }
  //     },
  //     {
  //       path: 'music-play',
  //       component: () => import('@/views/system/module-music/play-in'),
  //       meta: { title: '播放', hidden: false }
  //     },
  //   ]
  // },
  // {
  //   path: '/module-video',
  //   component: LayoutSystem,
  //   meta: { title: '视频模块', hidden: false, icon: '15726899' },
  //   children: [
  //     {
  //       path: 'module-video',
  //       component: () => import('@/views/system/module-video/index'),
  //       meta: { title: '资源管理', hidden: false }
  //     },
  //     {
  //       path: 'video-play',
  //       component: () => import('@/views/system/module-video/play'),
  //       meta: { title: '播放', hidden: false }
  //     },
  //   ]
  // },
  {
    path: '/module-backlog',
    component: LayoutSystem,
    meta: { title: '计划模块', hidden: false, icon: '10593764' },
    children: [
      {
        path: 'plan-list',
        component: () => import('@/views/system/module-backlog/plan-list'),
        meta: { title: '日月周年', hidden: false }
      },
      // {
      //   path: 'todo-list',
      //   component: () => import('@/views/system/module-backlog/todo-list'),
      //   meta: { title: '待办事项', hidden: false }
      // },
    ]
  },
  // {
  //   path: '/module-album',
  //   component: LayoutSystem,
  //   meta: { title: '相册模块', hidden: false, icon: '6129258' },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/system/module-album/index.vue'),
  //       meta: { title: '相册管理', hidden: false, icon: '6129258' }
  //     },
  //   ]
  // },
  {
    path: '/system',
    component: LayoutSystem,
    meta: { title: '系统页面', hidden: false, icon: '1727422' },
    children: [
      {
        path: 'data-dictionary',
        component: () => import('@/views/system/module-setting/data-dictionary/index'),
        meta: { title: '数据字典', hidden: false, icon: '30132183' }
      },
      {
        path: 'import-data',
        component: () => import('@/views/system/module-setting/import-data/index'),
        meta: { title: '数据导入', hidden: false }
      },
      {
        path: 'upload-file',
        component: () => import('@/views/system/module-setting/upload-file/index.vue'),
        meta: { title: '文件上传', hidden: false, icon: '4814554' }
      },
      // {
      //   path: 'icon-store',
      //   component: () => import('@/views/system/module-setting/icon-store/index.vue'),
      //   meta: { title: '图标库', hidden: false, icon: '1114662' }
      // },
    ]
  },
]