<template>
  <div class="menu-item">

    <!-- 有子路由 且大于一个 展示可折叠菜单 -->
    <template v-if="menuInfo.children && menuInfo.children.length > 1">
      <el-submenu :index="getFullPath(menuInfo)">
        <template slot="title">
          <part-icon :tag="menuInfo.meta.icon" />
          <span class="menu-item-title">{{ menuInfo.meta.title }}</span>
        </template>

        <template v-for="item of menuInfo.children">
          <menu-item :menuInfo="item" :key="item.path" :parent-path="menuInfo.path" />
        </template>

      </el-submenu>
    </template>

    <!-- 有子路由 但是只有一个 -->
    <template v-else-if="menuInfo.children && menuInfo.children.length == 1">
      <el-menu-item class="menu-item-only" :class="{ 'menu-item-only-active': $route.path == getFullPath(menuInfo.children[0]) }" :index="getFullPath(menuInfo.children[0])" @click="changePage(menuInfo.children[0])">
        <part-icon :tag="menuInfo.children[0].meta.icon" />
        <span slot="title" class="menu-item-title">{{ menuInfo.children[0].meta.title }}</span>
      </el-menu-item>
    </template>

    <!-- 没有子路由 展示单个路由 -->
    <template v-else>
      <el-menu-item :index="getFullPath(menuInfo)" @click="changePage(menuInfo)">
        <part-icon :tag="menuInfo.meta.icon" />
        <span slot="title" class="menu-item-title">{{ menuInfo.meta.title }}</span>
      </el-menu-item>
    </template>

  </div>
</template>

<script>
import MenuItem from './MenuItem'
export default {
  name: 'MenuItem',
  components: {
    MenuItem
  },
  props: {
    menuInfo: {
      require: true,
      type: Object,
      default() {
        return {}
      }
    },
    parentPath: String
  },
  methods: {
    changePage(menu) {
      let path = this.getFullPath(menu)
      if (path !== this.$route.path) {
        this.$router.push(path)
      }
    },
    getFullPath(menu) {
      return this.parentPath + '/' + menu.path
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  .part-icon {
    margin-right: 10px;
    color: currentColor;
  }

  .menu-item-title {
    color: inherit;
  }
}
</style>

<style lang="scss">
.menu-item {
  .el-submenu__title {
    .menu-item-title {
      font-weight: bold;
      color: #409eff;
    }
    .part-icon i {
      color: #409eff;
    }
    .el-submenu__icon-arrow {
      font-weight: bold;
      color: #409eff;
    }
  }
  // el-submenu 下的图标默认是灰色的，从外部设置的颜色不起作用
  .el-submenu__title i {
    /* color: #909399; */
    color: inherit;
  }

  // el-submenu 下对 el-icon 的图标设置了些样式，不覆盖的话自己组件的样式就不起作用了
  .el-submenu [class^='el-icon-'] {
    /* margin-right: 5px; */
    /* width: 24px; */
    margin-right: 0;
    width: auto;
  }

  // 只有一个子路由的样式
  .menu-item-only {
    .menu-item-title {
      font-weight: bold;
      color: #409eff;
    }
    .part-icon i {
      color: #409eff;
    }
    .el-submenu__icon-arrow {
      font-weight: bold;
      color: #409eff;
    }
    [class^='el-icon-'] {
      /* margin-right: 5px; */
      /* width: 24px; */
      margin-right: 0;
      width: auto;
    }
  }

  // 激活样式
  .menu-item-only-active {
    background-color: #545c64;
    .menu-item-title {
      font-weight: bold;
      color: #ffd04b;
    }
    .part-icon i {
      color: #ffd04b;
    }
    .el-submenu__icon-arrow {
      font-weight: bold;
      color: #ffd04b;
    }
  }
}
</style>
