// 可视化页面
import visualRoute from './modules/visual'

const baseRoutes = [
  {
    path: '/',
    redirect: '/infinity',
  },
  {
    path: '/infinity',
    component: () => import('@/views/infinity/index'),
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
  },
  {
    path: '/testpage',
    component: () => import('@/views/testpage/index'),
  },
  {
    path: '/upload-file',
    component: () => import('@/views/system/module-setting/upload-file/index'),
    meta: { title: '文件上传', hidden: true }
  },
  {
    path: '/music-play',
    component: () => import('@/views/system/module-music/play-out'),
    meta: { title: '音乐播放', hidden: true }
  },
  {
    path: '/video-play',
    component: () => import('@/views/system/module-video/play'),
    meta: { title: '视频播放', hidden: true }
  },
  {
    path: '/chat-gpt',
    component: () => import('@/views/chat-gpt/index'),
    meta: { title: 'chatGPT', hidden: true }
  },
  visualRoute,
]

/**
 * 从路由数组中获取所有路由的完整路径
 * @param {*} routes 
 * @param {*} rootPath 父级路径
 * @returns 路径数组
 */
function filterPath(routes, rootPath = '') {

  return routes.reduce((pre, currentItem, index) => {

    if (currentItem.path.startsWith('/')) {
      pre.push(currentItem.path)
    } else {
      pre.push(rootPath + currentItem.path)
    }

    if (currentItem.children) {
      pre = pre.concat(filterPath(currentItem.children, rootPath + currentItem.path + '/'))
    }

    return pre

  }, [])

}

// 路由路径白名单 
export const whitePath = filterPath(baseRoutes)

// 基础路由
export default baseRoutes