<!--
 * @Description: 可视化数据的根布局
-->
<template>
  <div class="layout-visual">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'LayoutVisual',
}
</script>

<style lang='scss' scoped>
.layout-visual {
  // min-height: 100vh;
  height: 100vh;
  // background-color: rgba($color: #000000, $alpha: 0.2);
}
</style>