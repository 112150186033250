<!--
 * @Description: MarkDown
-->
<template>
  <div class="part-mark-down" :id="id"></div>
</template>

<script>
import Editor from '@toast-ui/editor'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/i18n/zh-cn'     // i18n
export default {
  name: 'PartMarkDown',
  props: {
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default() {
        return 'editor-' + Math.random().toString().split('.')[1]
      }
    },
    placeholder: {      // 占位符
      type: String,
      default: '请输入内容...'
    },
    isView: {           // 是否只是查看
      type: Boolean,
      default: false
    },
    isPhoneEdit: {      // 是否是手机的编辑模式，会隐藏部分按钮
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: null,
      defaultToolbar: [
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        ['table', 'image', 'link'],
        ['code', 'codeblock'],
      ]
    }
  },
  watch: {
    value(newVal) {
      // 是预览模式的时候实现双向绑定
      if (this.isView) {
        this.editor.setMarkdown(newVal)
      }
    }
  },
  mounted() {
    this.initEditer()
  },
  beforeDestroy() {
    this.destroyEditor()
  },
  methods: {
    initEditer() {

      // PC端显示全部的工具栏，移动端只显示自定义的
      let toolbarItems = []
      if (this.isPhoneEdit) {
        toolbarItems = [
          [{
            el: this.insertButton(),
            tooltip: '上传图片'
          }]
        ]
      } else {
        toolbarItems = [
          // 默认工具栏
          ...this.defaultToolbar,
          // 自定义
          [{
            el: this.insertButton(),
            tooltip: '上传图片'
          }]
        ]
      }

      this.editor = new Editor.factory({
        el: document.getElementById(this.id),
        height: '50vh',
        initialValue: this.value,
        initialEditType: 'markdown',
        previewStyle: this.isPhoneEdit ? 'tab' : 'vertical',
        language: 'zh-CN',
        viewer: this.isView,
        hideModeSwitch: true,
        placeholder: this.placeholder,
        toolbarItems,   // 工具栏
      })

      if (!this.isView) {
        this.editor.on('change', () => {
          this.$emit('input', this.editor.getMarkdown())
        })
        this.$emit('input', this.editor.getMarkdown())
      }
    },
    insertButton() {      // 添加自定义按钮
      const button = document.createElement('button')

      button.className = 'toastui-editor-toolbar-icons last'
      button.style.backgroundImage = 'none'
      button.style.margin = '0'
      button.innerHTML = `<i style="font-style: normal;">上传</i>`
      button.addEventListener('click', () => {

        let element = document.createElement('input')
        element.type = 'file'

        element.addEventListener('change', (e) => {

          let formData = new FormData()
          formData.append('type', 'diary')
          formData.append('file', e.target.files[0])

          this.$http.post('/common/upload', formData).then(res => {

            if (this.isPhoneEdit) {
              this.$toast.success('上传成功')
            } else {
              this.$message.success('上传成功')
            }

            // 图片和视频插入的内容不一样
            // 图片 ![](${res.data.url})
            // 视频 <video controls src="${filePath}" style="height: 500px;" />

            let filePath = res.data.url

            let imgArr = ['.jpg', '.jepg', '.png', '.webp', '.gif']
            let videoArr = ['.mp4']

            if (imgArr.some(item => filePath.endsWith(item))) {
              this.editor.insertText(`\n![](${filePath})\n\n`)
            } else if (videoArr.some(item => filePath.endsWith(item))) {
              this.editor.insertText(`\n<video controls src="${filePath}" style="height: 500px;width: 100%;" />\n\n`)
            }

          })

        })

        element.click()

      })

      return button
    },
    destroyEditor() {
      if (this.editor) {
        this.editor.off('change')
        this.editor.destroy()
      }
    },
  }
}
</script>

<style lang='scss' scope>
.part-mark-down {
  // background-color: #ffffff;

  .toastui-editor-contents img {
    max-height: 400px;
  }
}
</style>

<style lang="scss">
.part-mark-down {
  img {
    max-height: 500px;
  }

  // 覆盖组件的默认样式
  .toastui-editor-contents p {
    display: flex;
  }

  .toastui-editor-contents p,
  .ProseMirror {
    text-indent: 2em;
    color: #000;
    font-size: 16px;

    img {
      margin: 0 auto;
    }
  }

  .ProseMirror,
  .toastui-editor-contents {
    font-family: 'PingFang-SC', 'Microsoft Yahei', Helvetica, Arial, sans-serif;
  }

  // 覆盖checkbox样式
  .task-list-item {
    p {
      text-indent: 10px;
    }
  }
  .toastui-editor-contents .task-list-item::before {
    // height: 22px;
    // width: 22px;
    top: 50%;
    // left: 50%;
    transform: translateY(-50%);

    background-size: cover;
  }
}
</style>