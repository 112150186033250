/** 
 * @description 操作 sessionStorage localStorage
 */

export const StorageDict = {
  /** 用户凭证 */
  TOKEN: 'TOKEN',
  /** 标记登录后返回上一个页面 */
  LOGIN_BACK: 'LOGIN_BACK',
  /** infinity 数据列表 */
  INFINITY_LIST: 'INFINITY_LIST',
  /** infinity 数据列表保存时间 后期会改成先渲染未过期数据，然后从服务端请求接口，如果有新的数据，再重新获取 */
  INFINITY_LIST_SAVETIME: 'INFINITY_LIST_SAVETIME',
}

/**
 * @description 向 sessionStorage 保存数据
 * @param { String } key 参数名
 * @param { String } val 参数值
 * @returns { void } 返回值
 */
export function setSessionStorage(key, val) {
  validateKey(key)
  validateVal(val)
  sessionStorage.setItem(key, val)
}

/**
 * @description 从 sessionStorage 保存数据
 * @param { String } key 参数名
 * @returns { String } 返回值
 */
export function getSessionStorage(key) {
  validateKey(key)
  return sessionStorage.getItem(key)
}

/**
 * @description 删除 sessionStorage 指定数据
 * @param { String } key 参数名
 * @returns { void } 返回值
 */
export function removeSessionStorage(key) {
  validateKey(key)
  sessionStorage.removeItem(key)
}

/**
 * @description 向 localStorage 保存数据
 * @param { String } key 参数名
 * @param { String } val 参数值
 * @returns { void } 返回值
 */
export function setLocalStorage(key, val) {
  validateKey(key)
  validateVal(val)
  localStorage.setItem(key, val)
}

/**
 * @description 从 localStorage 保存数据
 * @param { String } key 参数名
 * @returns { String } 返回值
 */
export function getLocalStorage(key) {
  validateKey(key)
  return localStorage.getItem(key)
}

/**
 * @description 删除 localStorage 指定数据
 * @param { String } key 参数名
 * @returns { void } 返回值
 */
export function removeLocalStorage(key) {
  validateKey(key)
  localStorage.removeItem(key)
}

/** 
 * @description 校验字典key是否存在于字典中，不存在则报错
 * @param { String } key 参数
 * @returns { void } 返回值
 */
function validateKey(key) {
  // if (!Object.keys(StorageDict).includes(key)) {
  //   throw new Error('字典不存在')
  // }
}

/** 
 * @description 校验字典值是否存在，不存在则报错
 * @param { String } key 参数名
 * @returns { void } 返回值
 */
function validateVal(val) {
  // if (val === undefined) {
  //   // throw new Error('字典值不存在', val)
  // }
}
