import localforage from 'localforage'

/** 缓存音频 */
export const indexedDBAudios = localforage.createInstance({
  name: 'audios'
})

/** 图片 */
export const indexedDBImages = localforage.createInstance({
  name: 'images'
})

/** 歌词 */
export const indexedDBLyrics = localforage.createInstance({
  name: 'lyrics'
})