import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import baseRoutes from './routes'

// 创建路由
function createRouter() {
  return new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { top: 0 }
      }
    },
    routes: baseRoutes
  })
}

const router = createRouter()

// 重置路由
export function resetRouter() {
  const baseRouter = createRouter()
  router.matcher = baseRouter.matcher
}

export default router