import { SET_ISLOGIN } from '../mutation-types'

const state = {
  isLogin: false
}

const mutations = {
  [SET_ISLOGIN]: (state) => {
    state.isLogin = true
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}