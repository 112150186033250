<template>
  <div id="app">
    <keep-alive :include="keepAlivePage">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { setSessionStorage, StorageDict } from '@/utils/storage'
export default {
  name: 'App',
  data() {
    return {
      keepAlivePage: [
        'FireEmblem',           // 火焰纹章 支援对话列表
      ],
    }
  },
}
</script>

<style lang="scss" scope>
#app {
  // font-family: 'PingFang-SC', 'Microsoft Yahei', Helvetica, Arial, sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica,
  //   Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif !important;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  // background-image: url('@/assets/img_001.jpg');
  // background-size: cover;
  // background-attachment: fixed;
  font-size: 16px;

  height: 100vh;
  width: 100vw;

  position: absolute;
}

html,
body {
  margin: 0;
  padding: 0;
  // background-color: #f0f2f5;
  overflow: hidden;
  font-size: 0;

  // PingFang 字体是在 layout 中定义的，只有进入后台页面才会加载字体
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Helvetica, Arial, Hiragino Sans GB, Microsoft YaHei, sans-serif !important;
}
</style>
