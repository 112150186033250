/*
 * @Description: 工具
 */

import _ from 'lodash'

/**
 * @description: 节流函数
 * @param { Function } func 需要节流的函数
 * @param { Number } time 时间，默认2000ms
 * @return {*}
 */
export function throttle(func, time) {
  return _.throttle(func, time || 1000, { trailing: false })
}

/**
 * @description: 防抖函数
 * @param { Function } func 需要防抖的函数
 * @param { Number } time 时间，默认500ms
 * @return {*}
 */
export function debounce(func, time) {
  return _.debounce(func, time || 500)
  // { leading: true }    延迟开始前是否调用
  // { trailing: false }  延迟结束后是否调用
}

/**
 * @description: 日期字符串拼接上星期
 * @param {*} dateStr 日期字符串，格式 2020-02-02
 * @returns 日期星期字符串，格式 2022-07-07 星期四
 */
export function formatDateWithWeek(dateStr) {
  let obj = {
    0: '星期日',
    1: '星期一',
    2: '星期二',
    3: '星期三',
    4: '星期四',
    5: '星期五',
    6: '星期六',
  }
  let date = new Date(dateStr)
  return dateStr + ' ' + obj[date.getDay()]
}

/**
 * 通过图片地址获取压缩后的文件地址
 * @param {*} originPath 原始文件路径
 * @returns 压缩文件路径
 */
export function getMinImgPath(originPath) {

  if (!originPath) {
    return
  }

  // 获取最后一个分隔符位置
  let lastIndex = originPath.lastIndexOf('/')

  // 截取出文件名称
  let fileName = originPath.substring(lastIndex + 1)

  // 拼接新的文件名称并更换进的文件名称
  let newUrl = originPath.replace(fileName, 'min.' + fileName)

  return newUrl
}

/** 
 * 获取指定范围内的随机数
 * 
 * 返回 [x, y]
 */
export function getRamdomNumberByRange(x, y) {
  return Math.round(Math.random() * (y - x) + x)
}