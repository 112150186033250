<template>
  <div class="navigation-bar">

    <part-icon tag="31543151" />
    <!-- <part-icon tag="31543153" /> -->

    <el-breadcrumb separator="/">
      <template v-for="item of matchedRoutes">
        <el-breadcrumb-item :key="item.path">{{ item.meta.title }}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>

    <!-- 退出登录 -->
    <el-popover class="logout-box" placement="bottom" width="160" v-model="visible">
      <p>确定退出登录吗？</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
        <el-button type="danger" size="mini" @click="handleClick">确定</el-button>
      </div>
      <el-button slot="reference" type="text">退出登录</el-button>
    </el-popover>

  </div>
</template>

<script>
import { removeToken } from '@/utils/auth'
import { resetRouter } from '@/router/index'
export default {
  name: 'NavigationBar',
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    matchedRoutes() {
      return this.$route.matched
    }
  },
  methods: {
    handleClick() {

      // 清空token
      removeToken()

      // 清空路由
      resetRouter()

      this.visible = false

      // 跳转首页
      this.$router.push('/')

    },
  }
}
</script>

<style lang='scss' scoped>
.navigation-bar {
  display: flex;
  align-items: center;

  .part-icon {
    margin: 0 20px;
  }

  // 退出登录
  .logout-box {
    margin-left: auto;
    margin-right: 20px;
  }
}
</style>