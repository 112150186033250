<!--
 * @Description: 系统页面的根布局
-->
<template>
  <div class="layout-system">

    <!-- 左侧菜单 -->
    <el-scrollbar class="left-menu">
      <el-menu class="el-menu-vertical-demo" :default-active="$route.path" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">

        <template v-for="item of menus">
          <menu-item :key="item.path" :menu-info="item" :parent-path="item.path" />
        </template>

      </el-menu>
    </el-scrollbar>

    <!-- 右侧路由视图 -->
    <div class="right-view">
      <navigation-bar />
      <div class="view-container">
        <router-view />
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuItem from './MenuItem'
import NavigationBar from './NavigationBar'
export default {
  name: 'LayoutSystem',
  components: {
    MenuItem,
    NavigationBar
  },
  computed: {
    ...mapGetters(['routes']),
    menus() {    // 获取路由中不隐藏的菜单
      return this.routes.filter(item => item.meta && !item.meta.hidden)
    }
  },
}
</script>

<style lang='scss' scoped>
.layout-system {
  display: flex;

  // 定义在这是为了让页面进入后台时才加载字体
  @font-face {
    font-family: 'PingFang-SC';
    src: url('@/assets/fonts/PingFang-SC-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  // background-color: rgba($color: #000000, $alpha: 0.1);

  $menuWidth: 200px;
  $navigationHeight: 50px;

  // 左侧菜单
  .left-menu {
    height: 100vh;
    width: $menuWidth;
    min-width: $menuWidth;
    background-color: rgb(84, 92, 100);
  }

  // 右侧路由视图
  .right-view {
    width: calc(100% - #{$menuWidth});

    .navigation-bar {
      height: $navigationHeight;
      background-color: #ffffff;
      box-shadow: 0 4px 4px rgba($color: #ffffff, $alpha: 0.2);
    }

    .view-container {
      height: calc(100vh - #{$navigationHeight});
      width: calc(100%);
      overflow: auto;
    }
  }
}
</style>

<style lang="scss">
.layout-system {
  // 隐藏水平滚动条
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  // 默认右侧会有 1px 的边框
  .el-menu {
    border: none;
    // height: 100%;
    // width: 100% !important;
  }
}
</style>