<!-- 
  暂时先实现能缓存到本地，后面再实现边缓存边播放
-->
<template>
  <audio ref="audioRef" :src="finalSrc" v-bind="$attrs" v-on="$listeners"></audio>
</template>

<script>
import { indexedDBAudios } from '@/utils/indexedDB'
import axios from 'axios'
export default {
  name: 'CacheAudio',
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      audioRef: null,
      finalSrc: '',
    }
  },
  watch: {
    src: {
      handler: async function (newVal) {

        if (!newVal) return

        this.renderAudio()

      },
      immediate: true
    }
  },
  mounted() {

    this.audioRef = this.$refs['audioRef']

  },
  methods: {
    async renderAudio() {

      this.$emit('startLoad')

      // 查询是否存在缓存的资源
      let existItem = await indexedDBAudios.getItem(this.src).then(result => {
        return result
      }).catch(function (err) {
        console.log('err', err)
        return null
      })

      // 查询是否存在缓存的音频
      if (!existItem) {
        // 不存在，则请求音频并缓存到本地，并保存响应头信息
        this.getResourceAndSave(this.src).then(result => {
          this.generateLocalURLAndSetToSrc(result.resource)
          this.$emit('endLoad')
        })
      } else {
        console.log('从 IndexedDB 获取')

        // 存在则先渲染保存的二进制数据
        this.generateLocalURLAndSetToSrc(existItem.resource)
        this.$emit('endLoad')

        // 然后发起请求判断资源是否过期
        // 过期则重新请求，重新渲染
        let isExpired = await this.queryResourceIsExpired(this.src, existItem.headers)
        if (isExpired) {
          this.getResourceAndSave(this.src).then(result => {
            this.generateLocalURLAndSetToSrc(result.resource)
          })
        }

      }

    },
    /** 生成本地链接并设置到scr */
    generateLocalURLAndSetToSrc(blob) {
      try {
        this.audioRef.src = window.URL.createObjectURL(blob)
      } catch (err) {
        console.log(err)
        this.audioRef.src = props.src
      }
    },
    /** 获取新资源并保存到数据库 */
    getResourceAndSave(url) {
      return new Promise((resolve, reject) => {
        let that = this
        axios({
          method: 'get',
          url,
          responseType: 'blob',
          onDownloadProgress: function (event) {
            if (event.lengthComputable) {
              let perNum = (event.loaded / event.total * 100).toFixed(2)
              that.$emit('loading', Number(perNum))
            }
          },
        }).then(res => {

          indexedDBAudios.setItem(url, {
            resource: res.data,
            headers: res.headers
          }).then(result => {
            console.log('从服务器获取', res.headers)
            resolve(result)
          }).catch((err) => {
            console.log('IndexedDB错误', err)
            reject(err)
          })

        }).catch(err => {
          console.log('axios错误', err)
          reject(err)
        })

      })
    },
    /** 判断资源是否过期 */
    queryResourceIsExpired(url, lastHeaders) {
      return new Promise((resolve, reject) => {
        // axios.options 返回405...
        axios.get(url, {
          headers: { range: 'bytes=0-0' }
        }).then(res => {
          if (lastHeaders['last-modified'] === res.headers['last-modified']) {
            resolve(false)
          } else {
            console.log('资源过期', lastHeaders['last-modified'], res.headers['last-modified'])
            resolve(true)
          }
        }).catch(err => {
          console.log('axios错误', err)
          reject(err)
        })
      })
    },
  }
}
</script>