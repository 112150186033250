/**
 * 导航守卫
 */

// 进度条
import NProgress from "nprogress"
import "nprogress/nprogress.css"

import router from './index'

import store from '@/store'
import { SET_ISLOGIN, ADD_ROUTES, INIT_ROUTES } from '@/store/mutation-types'

import { whitePath } from '@/router/routes'

import systemRoute from '@/router/modules/system'
import errorRoute from '@/router/modules/error'

import { getToken } from '@/utils/auth'

router.beforeEach((to, from, next) => {

  // 进度条开始
  NProgress.start()

  document.title = to.meta.title || '夜尽天明'

  if (getToken()) {

    if (store.state.user.isLogin) {
      next()
    } else {
      store.commit(`user/${SET_ISLOGIN}`)

      // 添加路由
      systemRoute.forEach(item => {
        router.addRoute(item)
      })
      router.addRoute(errorRoute)

      // 初始化store中的路由
      if (store.getters.routes.length === 0) {
        store.commit(`route/${INIT_ROUTES}`)
      }

      // 添加新路由到store中
      store.commit(`route/${ADD_ROUTES}`, systemRoute)
      store.commit(`route/${ADD_ROUTES}`, errorRoute)

      next({ ...to, replace: true })
    }

  } else {
    if (whitePath.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }

})

router.afterEach((to, from) => {

  // 进度条关闭
  NProgress.done()

  // ...

})