/*
 * @Description: 
 */
import axios from 'axios'
import { getToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui'
import router from '@/router'
import { throttle } from '@/utils'
import { StorageDict, setSessionStorage, getSessionStorage } from '@/utils/storage'

// 节流函数 直接写throttle不起作用，得看看防抖节流的实现原理才能解答这个问题
let throttleObj = {
  throttle: throttle(function () {

    // 设置标记
    // 登录页面登录后，有这个标记需要返回上个页面
    // 没有则进入系统
    setSessionStorage(StorageDict.LOGIN_BACK)

    Message.warning({
      message: '登录过期！请重新登录！'
    })
    removeToken()
    router.push('/login')
  })
}

// 创建实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  timeout: 5000 // request timeout
  // timeout: 0,    // 不限制时间
})

// 请求监听
service.interceptors.request.use(
  config => {
    // 可以在这设置token
    config.headers['token'] = getToken()
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应监听
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    console.log(error)
    if (error.response.status === 401) {
      // 节流函数
      throttleObj.throttle()
    }

    return Promise.reject(error)
  }
)

export default service