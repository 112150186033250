<!-- 
  富文本编辑器 Tinymce
-->
<template>
  <div class="part-tinymce">
    <textarea :id="tinymceId">

    </textarea>
  </div>
</template>

<script>

const tinymceCDN = 'https://cdn.tiny.cloud/1/qm19mfcakwwzagq5w83o325gd7uxn2w6e4qlj56x52mjmkxe/tinymce/6/tinymce.min.js'
// const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
// const tinymceCDN = 'https://cdn.tiny.cloud/1/no-api-key/tinymce/6/tinymce.min.js'
import dynamicLoadScript from './dynamicLoadScript'

export default {
  name: 'PartTinymce',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tinymceId: 'mytextarea',
      isDestroy: false,
    }
  },
  // watch: {
  //   value(val) {
  //     this.$nextTick(() => {
  //       console.log('设置呢你荣' + val)
  //       this.setContent(val || '')
  //     })
  //   },
  // },
  mounted() {
    this.isDestroy = false
    this.loadResource()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    loadResource() {
      dynamicLoadScript(tinymceCDN, (err) => {
        if (err) {
          this.$message.warning(err.message)
          return
        }
        this.initTinymce()
      })
    },
    initTinymce() {
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        language: 'zh_CN',
        plugins: [
          'lists', 'advlist', 'autolink', 'link', 'image', 'charmap', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
          'bold italic backcolor forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat subscript superscript',
        content_style: 'body { font-family: PingFang-SC, Microsoft Yahei, Helvetica, Arial, sans-serif; font-size: 16px; }',
        toolbar_mode: 'floating',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        init_instance_callback: editor => {

          // 解决下拉菜单不显示的问题
          let element = document.querySelector('.tox-tinymce-aux')
          if (element) {

            // 不在弹窗中 解决方法一（不需要样式覆盖）
            // element.style.position = 'revert' 

            // 不在弹窗中 解决方法二（需要样式覆盖）
            // element.style.position = 'absolute'

            // 在弹窗中 （需要样式覆盖）
            element.style.position = 'absolute'
            element.style.zIndex = '5000'
          }

          // 内容改变时，通知父组件
          editor.on('NodeChange Change KeyUp SetContent', () => {
            if (!this.isDestroy) {
              // 当组件销毁的时候，这个方法不执行
              this.$emit('input', editor.getContent())
            }
          })

          // 初始化内容
          if (this.value) {
            this.setContent(this.value)
          }

        }
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get('mytextarea')
      if (tinymce) {
        this.isDestroy = true
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    // getContent() {
    //   window.tinymce.get(this.tinymceId).getContent()
    // },
  }
}
</script>

<style lang='scss' scoped>
.part-tinymce {
  position: relative;
}
</style>