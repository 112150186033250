/**
 * 图片懒加载
 * 进入可视区域时才开始加载
 */

export default {
  name: 'lazy',
  inserted(el) {

    // 拿到真实的图片地址，并清空dom，禁止加载图片
    const imgSrc = el.src
    el.src = ''

    // 观察者
    const observer = new IntersectionObserver((obs) => {

      // 观察的对象是否在可视区
      let isInViewport = obs[0].isIntersecting

      if (isInViewport) {
        // 加载图片
        el.src = imgSrc
        // 停止观察
        observer.unobserve(el)
        // 关闭观察器实例
        observer.disconnect()
      }

    })
    observer.observe(el)

  }
}